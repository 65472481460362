import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule ],
  selector: 'lux-blank-coin',
  standalone: true,
  templateUrl: './blank-coin.component.svg',
})
export class BlankCoinComponent {}
