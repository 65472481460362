// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  width: 100%;
  height: 100%;
}
svg circle {
  fill: #fff;
  stroke: #9faaae;
  stroke-width: 2;
}
svg .filled {
  fill: #29a59a;
  stroke: none;
}

.check-circle-container {
  position: relative;
}

.checkmark {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
