// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incentive-widget-wrapper {
  border: 1px solid #d6dbdc;
  border-radius: 0.375rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  text-align: center;
}
.incentive-widget-wrapper a:hover {
  text-decoration: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;
}

h3 {
  flex: 1;
  margin: 0;
  padding-left: 1rem;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 600;
}

svg {
  flex-shrink: 0;
}

.info {
  margin: 0 1rem;
  padding-top: 1rem;
  color: #475053;
  font-size: 1rem;
}

.earnings {
  padding: 1.5rem 0 0 1rem;
  text-align: left;
  color: #b37700;
  font-size: 1rem;
  font-weight: 600;
}
.earnings.complete {
  font-weight: 600;
}

.goal-text {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  padding-left: 0.4rem;
  text-align: start;
  color: #181b1c;
}
.goal-text .goal-info {
  margin: 0;
  padding-top: 1rem;
  font-size: 1rem;
}

h4 {
  font-size: 1rem;
  font-weight: 600;
}

.goal-name {
  box-sizing: border-box;
  position: relative;
  padding: 0.5rem 0 0.25rem 1rem;
  width: 90%;
  text-align: left;
  color: #000;
  font-weight: 700;
}
.goal-name ::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 1rem;
  background-color: #d6dbdc;
  height: 2px;
  content: "";
}

.goal {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 1rem 0.5rem;
}
.goal:hover {
  text-decoration: none;
}

.chart {
  max-width: 3.75rem;
}

.see-details {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
