import { NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ NgIf, NgOptimizedImage ],
  selector: 'lux-check-circle',
  standalone: true,
  styleUrls: [ './check-circle.component.scss' ],
  templateUrl: './check-circle.component.html',
})
export class CheckCircleComponent {
  /** Optional checkmark black/white toggle for use with different fill colors, defaults to white */
  @Input() public blackCheckmark: boolean = false;

  @Input() public complete: boolean = false;

  /** Optional circle fill color, defaults to lux green */
  @Input() public completedFillColor: string = '#29a59a;';

  /** Optional size (in px), defaults to 50px */
  @Input() public size: number = 50;
}
