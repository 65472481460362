import { Injectable } from '@angular/core';
import {
  forkJoin,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FirebaseUser } from '@app/angular-fire-shims/angular-fire-auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService, FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { CollectionReference, DocumentReference, Transaction } from '@app/angular-fire-shims/angular-firestore.service';
import { AuthService } from '@app/auth/auth.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports

import { EventsService } from '@app/events/events.service'; // eslint-disable-line @typescript-eslint/consistent-type-imports
import { EVENTS } from './user-monitor';

interface UserProgressBase {
  id?: string; // Slug for Lesson not always included when firestore valueChanges is used.

  createdAt: Date | FirestoreTimestamp;
  lastAccessed: Date | FirestoreTimestamp;
  maxSlide: number;
  totalSlides: number;
}

export interface UserProgress extends UserProgressBase {
  createdAt: Date;
  lastAccessed: Date;
}

// Should only be used for testing!
export interface UserProgressFirestore extends UserProgressBase {
  createdAt: FirestoreTimestamp;
  lastAccessed: FirestoreTimestamp;
}

export interface UserProgressMap {
  // Lesson Slug: Max Slide Number Reached
  [slug: string]: UserProgress;
}

@Injectable({ providedIn: 'root' })
export class UserProgressService {
  public readonly progress$: Observable<UserProgressMap>;

  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly eventsService: EventsService,
  ) {
    this.progress$ = this._getAllProgress();
  }

  public getLessonProgress(slug: string): Observable<UserProgress | undefined> {
    return this.progress$.pipe(map((progressMap: UserProgressMap): UserProgress | undefined => progressMap[slug]));
  }

  public setLessonProgress(slug: string, maxSlide: number, totalSlides: number): Observable<boolean> {
    const updateProgress$ = this._setLessonProgress(slug, maxSlide, totalSlides);

    if (maxSlide === totalSlides) {
      return forkJoin([
        updateProgress$,
        // Note that a user can trigger multiple completions for a lesson by navigating back and forth
        // between the last slide and the previous slide. However Incentive programs and such generally
        // only care about unique completions of lessons so this probably isn't an issue.
        this.eventsService.recordEventActions(
          {
            actions: [ 'userHistoricalTracking', 'eventCollectionTracking' ],
            eventName: EVENTS.lessonsLessonProgressCompleted,
            key: slug,
            metadata: { maxSlide, slug, totalSlides },
            type: EVENTS.lessonsLessonProgressCompleted,
          },
        ),
      ]).pipe(map((): boolean => true));
    }
    return updateProgress$;
  }

  /**
   * Object representing all of the progress for a user across all of the lessons.
   * lessons that haven't be started will be undefined.
   */
  private _getAllProgress(): Observable<UserProgressMap> {
    return this.authService.currentUser$.pipe(
      map(
        (user: FirebaseUser): CollectionReference<UserProgressFirestore> =>
          this.afStore.collection<UserProgressFirestore>(`users/${user.uid}/progress`),
      ),
      switchMap(
        (collRef: CollectionReference<UserProgressFirestore>): Observable<UserProgressFirestore[]> =>
          this.afStore.collectionData(collRef, { idField: 'id' }), // Need the IDs
      ),
      map((rawProgs: UserProgressFirestore[]): UserProgressMap => {
        const progress: UserProgressMap = {};

        for (const rawProg of rawProgs) {
          // Firestore timestampsInSnapshots defaults to true since v5 of angularfire2.
          // So even though we put up a date, firestore is going to give us back a Timestamp that
          // we must jump through hoops to correct.
          // Added a createdAt field after this was already in use so we have to handle it not existing sometimes.
          let createdAt: Date;
          try {
            createdAt = rawProg.createdAt.toDate();
          } catch {
            createdAt = rawProg.lastAccessed.toDate();
          }
          const prog: UserProgress = {
            ...rawProg,
            createdAt,
            lastAccessed: rawProg.lastAccessed.toDate(),
          };
          // This should always be true, but tsc doesn't know that.
          if (rawProg.id) {
            progress[rawProg.id] = prog;
          }
        }

        return progress;
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  private _setLessonProgress(slug: string, maxSlide: number, totalSlides: number): Observable<boolean> {
    return this.authService.requireUser$.pipe(
      map(
        (user: FirebaseUser): DocumentReference<UserProgressFirestore> =>
          this.afStore.doc<UserProgressFirestore>(`users/${user.uid}/progress/${slug}`),
      ),
      switchMap(async (userDoc: DocumentReference<UserProgressFirestore>): Promise<boolean> => {
        // Idea for this from Stack Overflow: https://stackoverflow.com/a/48213506
        await this.afStore.runTransaction(
          async (transaction: Transaction): Promise<Transaction> => {
            const nowTs = FirestoreTimestamp.now();
            const userDocSnap = await transaction.get(userDoc);
            const createdAt = (userDocSnap.get('createdAt') ?? nowTs) as FirestoreTimestamp;
            const payload: UserProgressFirestore = {
              createdAt,
              lastAccessed: nowTs,
              maxSlide,
              totalSlides,
            };

            return transaction.set(userDoc, payload);
          },
        );
        return true;
      }),
    );
  }
}
