import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map, switchMap } from 'rxjs';
import type { Observable } from 'rxjs';

import { DefaultCurrencyPipe } from '@app/pipes/default-currency/default-currency.pipe';
import { CheckCircleComponent } from '@app/stuff/check-circle/check-circle.component';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { FifthThirdIncentiveProgressService, Goal2Progress } from '../fifth-third-incentive-progress.service';
import { IncentiveComingSoonWidgetComponent } from '../incentive-coming-soon-widget/incentive-coming-soon-widget.component';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { IncentiveProgramService } from '../incentive-program.service';

export interface ViewModel {
  currentIncentiveProgramEarnings: number;
  goal1Complete: boolean;
  goal2Progress: Goal2Progress;
  showProgram: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckCircleComponent,
    CommonModule,
    DefaultCurrencyPipe,
    IncentiveComingSoonWidgetComponent,
    RouterModule,
  ],
  selector: 'lux-fifth-third-incentive-widget',
  standalone: true,
  styleUrls: [ './fifth-third-incentive-widget.component.scss' ],
  templateUrl: './fifth-third-incentive-widget.component.html',
})
export class FifthThirdIncentiveWidgetComponent {
  public readonly goal1Earnings: number = 100;
  public readonly goal2Earnings: number = 400;
  public readonly maxCheckupEarnings: number = 300;
  public readonly maxEarnings: number = 500;
  public readonly maxLessons: number = 10;
  public readonly maxLessonsEarnings: number = 300;
  public readonly vm$: Observable<ViewModel>;

  constructor(
    private readonly fifthThirdIncentiveProgressService: FifthThirdIncentiveProgressService,
    private readonly incentiveProgramService: IncentiveProgramService,
  ) {
    this.vm$ = this.incentiveProgramService.incentiveSummaryProgramId$.pipe(
      switchMap((summaryProgramId: string | undefined): Observable<ViewModel> => {
        return (
          summaryProgramId == undefined
            ? this.incentiveProgramService.incentiveProgramProgress$ // get current program data
            : this.incentiveProgramService.getIncentiveProgramProgress(summaryProgramId) // get previous program data
        ).pipe(
          switchMap((): Observable<ViewModel> => {
            return combineLatest([
              this.fifthThirdIncentiveProgressService.goal1Complete$,
              this.fifthThirdIncentiveProgressService.goal2Progress$,
            ]).pipe(
              map(([ goal1Complete, goal2Progress ]: [boolean, Goal2Progress]): ViewModel => {
                const currentIncentiveProgramEarnings = (goal1Complete ? this.goal1Earnings : 0)
                  + (goal2Progress.goalComplete ? this.goal2Earnings : 0);

                return {
                  currentIncentiveProgramEarnings,
                  goal1Complete,
                  goal2Progress,
                  showProgram: summaryProgramId == undefined,
                };
              }),
            );
          }),
        );
      }),
    );
  }
}
