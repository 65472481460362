import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  map,
  of,
  switchMap,
} from 'rxjs';

import { DefaultCurrencyPipe } from '@app/pipes/default-currency/default-currency.pipe';
import { CheckCircleComponent } from '@app/stuff/check-circle/check-circle.component';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { IncentiveProgramProgressService } from '../incentive-program-progress.service';
import type { Goal, Program, ProgramProgress } from '../types';
import { BlankCoinComponent } from './blank-coin/blank-coin.component';

interface ViewModel {
  goals: Goal[];
  isLoading: boolean; // Loading indicator
  program?: Program; // The incentive program
  progress?: ProgramProgress; // User's program progress
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckCircleComponent,
    CommonModule,
    BlankCoinComponent,
    DefaultCurrencyPipe,
    RouterModule,
  ],
  selector: 'lux-incentive-program-widget',
  standalone: true,
  styleUrls: [ './incentive-program-widget.component.scss' ],
  templateUrl: './incentive-program-widget.component.html',
})
export class IncentiveProgramWidgetComponent {
  public readonly vm$: Observable<ViewModel>;

  constructor(private readonly incentiveProgramProgressService: IncentiveProgramProgressService) {
    this.vm$ = this.incentiveProgramProgressService.getFirstProgram().pipe(
      switchMap((program: Program | undefined): Observable<ViewModel> => {
        if (!program) {
          // No program found, return empty state
          return of({
            goals: [],
            isLoading: false,
            program: undefined,
            progress: undefined,
          });
        }

        // Fetch goals and progress for the program
        const goals$ = this.incentiveProgramProgressService.getGoalsForProgram(program.id);
        const progress$ = this.incentiveProgramProgressService.getProgramProgress(program.id);

        // Combine program, goals, and progress
        return combineLatest([ of(program), goals$, progress$ ]).pipe(
          map(([ programData, goals, progress ]: [Program, Goal[], ProgramProgress | undefined]): ViewModel => {
            // Map the goals to include a `completed` field
            const sortedGoals = goals
              .map((goal: Goal): Goal & { completed: boolean } => {
                const goalProgressEntry = progress?.goalProgress.find(
                  (gp: { completed?: boolean; goalId: string }): boolean => gp.goalId === goal.id,
                );
                return {
                  ...goal,
                  completed: goalProgressEntry?.completed ?? false,
                };
              })
              .sort((a: Goal & { completed: boolean }, b: Goal & { completed: boolean }): number =>
                (a.position ?? 0) - (b.position ?? 0));
            return {
              goals: sortedGoals,
              isLoading: false,
              program: programData,
              progress: progress ?? undefined,
            };
          }),
        );
      }),
      // Emit loading state initially
      map((viewModel: ViewModel): ViewModel => ({
        ...viewModel,
        isLoading: !viewModel.program,
      })),
    );
  }

  public trackByGoalId(_index: number, goal: Goal): string {
    return goal.id;
  }
}
