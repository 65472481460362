import type { FirestoreTimestamp } from '@app/angular-fire-shims/angular-firestore.service';
import type { SelectOption } from '@app/user-input/user-input';

/**
 * Configurations for different aspects of the app that need per client customization.
 */
export interface AdvisorConfigFirestore {
  readonly callEnabled: boolean;
  readonly certificationBoard: string;
  readonly certificationBoardLink: string;
  readonly chatEnabled: boolean;
  readonly plannerCalendarUrls: PlannerCalendar;
  readonly spanishEnabled: boolean;
  // Cannot be used when advisor/external page is in use
  readonly specialRequest: boolean;
  // Only 1 typeform can be used for advisor/external
  // or advisor/request page
  readonly typeform?: {
    readonly connectEnabled?: boolean;
    readonly formId: string;
    readonly title: string;
  };
}

interface PlannerCalendar {
  calendly?: CalendarMeetingConfig[];
  hubspot?: CalendarMeetingConfig[];
}

export interface CalendarMeetingConfig {
  readonly locale: string;
  readonly url: string;
}

export interface AdvisorConfig extends AdvisorConfigFirestore {
  readonly enabled: boolean; // True if any of callEnabled, chatEnabled or typeform are truthy.
  readonly referenceEnabled: boolean; // True if either callEnabled or chatEnabled are true.
}

export const DEFAULT_ADVISOR_CONFIG: AdvisorConfig = {
  callEnabled: false,
  certificationBoard: 'Certified Financial Planner Board of Standards',
  certificationBoardLink: 'https://www.cfp.net/',
  chatEnabled: false,
  // catchError doesn't calculate the enabled property, so set the default here also.
  enabled: false, // Service will automatically set this flag based on `callEnabled || chatEnabled || !!typeform`
  plannerCalendarUrls: {
    calendly: [
      {
        locale: 'en-US',
        url: 'https://calendly.com/learnlux/30min',
      },
    ],
  },
  referenceEnabled: false,  // Service will automatically set this flag based on `callEnabled || chatEnabled`
  spanishEnabled: false,
  specialRequest: false,
};

export interface BannerConfig {
  readonly content: string;
  readonly dismissable: boolean;
  readonly hide: boolean;
  readonly msgVersion: string;
  readonly translationKeys?: {
    readonly content: string;
  };
}

export type BannerConfigOrUndef = BannerConfig | undefined;

interface IncentiveConfigBase {
  readonly endAt: Date | FirestoreTimestamp;
  /**
   * For `incentiveConfig` defines the id of the program currently being tracked.
   * For `incentiveSummary` defines the id of the program being summarized.
   */
  readonly programId: string;
  readonly startAt: Date | FirestoreTimestamp;
}

export interface IncentiveConfigFirestore extends IncentiveConfigBase {
  readonly endAt: FirestoreTimestamp;
  readonly startAt: FirestoreTimestamp;
}

export interface IncentiveConfig extends IncentiveConfigBase {
  readonly endAt: Date;
  readonly startAt: Date;
}

interface FeaturesConfigBase {
  readonly accountsEnabled: boolean; // Controls if the Accounts route is available.
  readonly benefitsEnabled: boolean; // Controls the Benefits menu item in the head nav.
  readonly budget: 'disabled' | 'generic' | 'us'; // Controls if the Budget route is available.
  readonly budgetInsightsEnabled: boolean; // Enables the link to the budget insights page. accounts and budget should both be enabled.
  readonly calcDebtPayoff: boolean; // Enables the Debt Payoff Calculator.
  readonly calcEmergencySavings: boolean; // Enables the Emergency Saving Calculator.
  readonly calcSavingsGoal: boolean; // Enables the Savings Goal Calculator.
  readonly checkupEnabled: boolean; // Controls if the Checkup route is available.
  readonly dataConsentEnabled: boolean; // Controls if data consent is required.
  readonly emailLoginEnabled: boolean; // Controls if the email login form is available.
  readonly goalsEnabled: boolean; // Controls if the Goals route is available.
  readonly incentiveProgramEnabled: boolean; // Controls if the incentive program should show on the /dashboard.
  readonly plannerCallDetails: boolean; // Controls if the schedule a planner call details page shows.
  readonly retirementCalcEnabled: boolean; //  Controls if the RetirementProjectionComponent is displayed on /goals/retirement
  readonly wistiaId: string; // Controls the Wistia video displayed on auth page
}

export interface FeaturesConfigFirestore extends FeaturesConfigBase {
  readonly incentiveConfig?: IncentiveConfigFirestore;
  readonly incentiveSummary?: IncentiveConfigFirestore;
}

export interface FeaturesConfig extends FeaturesConfigBase {
  readonly calculatorsEnabled: boolean; // True if any of calcDebtPayoff, calcEmergencySavings, or retirementCalcEnabled are truthy.
  readonly incentiveConfig?: IncentiveConfig; // Optional configuration for incentive programs with special time frames.
  readonly incentiveSummary?: IncentiveConfig; // Optional configuration for incentive program summaries
}

export const DEFAULT_FEATURES_CONFIG: FeaturesConfig = {
  accountsEnabled: false, // Optional upgrade, default disabled
  benefitsEnabled: false, // Optional upgrade, default disabled
  budget: 'us', // Default feature for U.S. clients
  budgetInsightsEnabled: false, // Beta feature, default disabled
  calcDebtPayoff: false, // Optional, default disabled except U.S. clients
  calcEmergencySavings: false, // Optional, default disabled except U.S. clients
  calcSavingsGoal: false, // Enables the Savings Goal Calculator.
  calculatorsEnabled: false, // Service will automatically set this flag based on keys in `DEFAULT_US_CALCULATORS`
  checkupEnabled: true, // Default feature for all clients, enabled
  dataConsentEnabled: false, // Not needed for all clients
  emailLoginEnabled: true, // Allow email logins. (but might be turned off if provider logins are enabled in SelfSignupConfig)
  goalsEnabled: true, // Default feature for all clients, enabled
  incentiveProgramEnabled: false, // Default false, only enabled for a certain bank
  plannerCallDetails: false, // Default false, dev flag
  retirementCalcEnabled: false, // Optional, default disabled except U.S. clients
  wistiaId: '3c5dh0k4v7',
} as const;

type USCalculatorFlags = Pick<
  FeaturesConfig,
  | 'calcDebtPayoff'
  | 'calcEmergencySavings'
  | 'calcSavingsGoal'
  | 'retirementCalcEnabled'
>;
export const DEFAULT_US_CALCULATORS: USCalculatorFlags = {
  calcDebtPayoff: true,
  calcEmergencySavings: true,
  calcSavingsGoal: true,
  retirementCalcEnabled: true,
};

export interface Country {
  readonly label: string;
  readonly link: string;
  readonly translationKey?: string;
}

export interface GlobalConfig {
  readonly countries: readonly Country[];
  readonly default?: Country;
  readonly enabled: boolean;
}

export const DEFAULT_GLOBAL_CONFIG: GlobalConfig = {
  countries: [],
  enabled: false, // Service will automatically set this flag based on `default.label && countries.length > 1`
} as const;

export interface OrganizationConfig {
  employerDivisions: SelectOption[];
}

export const DEFAULT_ORG_CONFIG: OrganizationConfig = {
  employerDivisions: [],
};

export interface ProviderConfig {
  /**
   * https://firebase.google.com/docs/auth/web/openid-connect#web-version-9
   * https://firebase.google.com/docs/reference/js/auth.oauthprovider
   * https://firebase.google.com/docs/auth/web/saml#web-version-9
   * https://firebase.google.com/docs/reference/js/auth.samlauthprovider
   */
  readonly authProvider: 'OIDC' | 'SAML';
  readonly emailLinkingEnabled?: boolean; // Controls if Provider (SSO/SAML) signups also link an email and password and the reverse.
  readonly externalLogoutUrl?: string; // United wants to also logout their SSO when logging out from LearnLux.
  readonly helpTextLink?: string; // Provides instructions to the member when linking the provider.
  readonly helpTextLogin?: string; // Provides instructions to the member when logging in with the provider.
  readonly label?: string; // Button text to provider popup. If not set, providerLabel is used and must be set.
  // TODO move this somewhere else where it belongs
  readonly linked?: boolean; // Is the listed provider linked for the User already
  readonly providerId: string; // Passed to the AuthProvider constructor (This might need to be optional for some providers!)
  readonly providerLabel?: string; // Human friendly name of the provider. ex. JumpCloud
  readonly required?: boolean; // Determines if connecting this provider is optional
  readonly translationKeys?: { // Required to provide translations for the listed keys
    helpTextLink?: string;
    helpTextLogin?: string;
  };
}

export interface RpcConfig {
  readonly buttonLink: string;
  readonly buttonText: string;
  readonly enabled: boolean;
  readonly phoneNumber: string;
}

export type RpcConfigOrUndef = RpcConfig | undefined;

export interface SelfSignupConfig {
  readonly allowedDomains: readonly string[]; // Only used for Email Signup
  readonly emailRequired?: boolean; // Determines if linking an email/password to this account is optional
  readonly enabled: boolean; // Enables email joining the app.
  readonly loginMessage?: string; // Show a custom message at login.
  readonly password: {
    readonly minLength: number;
    readonly minStrength: number;
  };
  readonly providers: readonly ProviderConfig[];
}

export const DEFAULT_SELFSIGNUP_CONFIG: SelfSignupConfig = {
  allowedDomains: [],
  enabled: false,
  password: { // Beware that password object will not be deep merged!
    minLength: 8,
    minStrength: 45,
  },
  providers: [],
} as const;

export interface LocaleOption {
  readonly id: string; // Locale ID: en_US
  readonly name: string; // Display name
}

export interface TranslationConfig {
  readonly default: string; // This should match what is in AppConfig
  readonly enabled: boolean;
  readonly locales: readonly LocaleOption[];
}

export const DEFAULT_TRANSLATION_CONFIG: TranslationConfig = {
  default: 'en-US', // Default locale for AppConfig
  enabled: false, // Translation feature flag
  locales: [ // Supported locales member can pick from
    { id: 'en-US', name: 'English (US)' },
  ],
} as const;

export interface AuthConfig {
  sessionTimeout: {
    enabled?: boolean;
    timeoutMs?: number;
  };
}
