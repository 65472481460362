import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import {
  from, map, mergeMap, of, switchMap,
} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AngularFirestoreService } from '@app/angular-fire-shims/angular-firestore.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { AuthService } from '@app/auth/auth.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { HistoricalEventBase, UserHistoricalService } from '@app/users/user-historicals.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserTrackingService } from '@app/users/user-tracking.service';

import type { FirebaseUser } from '../angular-fire-shims/angular-fire-auth.service';

export type EventAction = 'eventCollectionTracking' | 'userEventTracking' | 'userHistoricalTracking';

export interface EventDataFirestore {
  timestamp: Date;
  type: string;
  userId: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private readonly afStore: AngularFirestoreService,
    private readonly authService: AuthService,
    private readonly userTrackingService: UserTrackingService,
    private readonly userHistoricalService: UserHistoricalService,
  ) {}

  public recordEventActions(params: {
    actions: EventAction[];
    eventName?: string;
    key?: string;
    metadata: HistoricalEventBase['metadata'];
    type: string;
    userEventName?: string;
  }): Observable<void> {
    const actionHandlers: Record<EventAction, () => Observable<void>> = {
      eventCollectionTracking: (): Observable<void> => {
        return this.authService.currentUser$.pipe(
          switchMap((user: FirebaseUser): Observable<void> => {
            const dataCol = this.afStore.collection<EventDataFirestore>('events');
            const payload = {
              metadata: params.metadata,
              timestamp: new Date(),
              type: params.type,
              userId: user.uid,
            };
            return from(this.afStore.addDoc(dataCol, payload)).pipe(map((): void => undefined));
          }),
        );
      },
      userEventTracking: (): Observable<void> => {
        const eventName = params.userEventName ?? params.eventName;
        return eventName
          ? (this.userTrackingService.trackEvent(eventName, params.metadata), of(undefined))
          : of(undefined);
      },
      userHistoricalTracking: (): Observable<void> => {
        return params.eventName && params.key
          ? this.userHistoricalService.recordEvent(params.eventName, params.key, params.metadata)
          : of(undefined);
      },
    };

    const actionFuncs: Array<() => Observable<void>> = [];

    for (const action of params.actions) {
      const handler = actionHandlers[action];
      actionFuncs.push(handler);
    }

    return actionFuncs.length > 0
      ? from(actionFuncs).pipe(mergeMap((func: () => Observable<void>): Observable<void> => func()))
      : of(undefined);
  }
}
