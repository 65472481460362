import { Injectable } from '@angular/core';
import { endOfYear, startOfYear } from 'date-fns';
import {
  combineLatest,
  map,
  of,
  switchMap,
} from 'rxjs';
import type { Observable } from 'rxjs';

import type { FeaturesConfig, IncentiveConfig } from '@app/client-config/client-config';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { ClientConfigService } from '@app/client-config/client-config.service';
import type { HistoricalSummary, UserHistorical } from '@app/users/user-historicals.service';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { UserHistoricalService } from '@app/users/user-historicals.service';
import { EVENTS } from '@app/users/user-monitor';
import { getProgramId } from '@app/utilities/incentive-programs';

import { UserDataService } from '../users/user-data.service';  // eslint-disable-line @typescript-eslint/consistent-type-imports
import type { UserData } from '../users/user';

export interface IncentiveProgramProgress {
  readonly completedLessonSlugs: string[];
  readonly hasAnsweredCheckup: boolean;
  readonly hasVisitedCheckupResults: boolean;
  readonly isBudgetComplete: boolean;
  readonly isDebtPayoffCalculatorComplete: boolean;
  readonly isEmergencySavingsCalculatorComplete: boolean;
  readonly isRetirementCalculatorComplete: boolean;
  readonly isSavingsGoalCalculatorComplete: boolean;
  readonly lessonsCompleted: number;
}

@Injectable({ providedIn: 'root' })
export class IncentiveProgramService {
  /**
   * Fetches the custom incentive program configuration from the Features config, if speficied.
   * Otherwise generates the default yearly incentive configuration.
   */
  public readonly incentiveConfig$: Observable<IncentiveConfig>;

  /**
   * Calculates the current incentive progress from the current user historicals data.
   */
  public readonly incentiveProgramProgress$: Observable<IncentiveProgramProgress>;

  /**
   * If specified fetches the program ID of historical program being summerized from the features
   * config. Otherwise it will emit undefined if there is no program to summerize.
   */
  public readonly incentiveSummaryProgramId$: Observable<string | undefined>;

  constructor(
    private readonly clientConfig: ClientConfigService,
    private readonly userDataService: UserDataService,
    private readonly userHistoricalService: UserHistoricalService,
  ) {
    this.incentiveConfig$ = this.clientConfig.featuresConfig$.pipe(
      map((config: FeaturesConfig): IncentiveConfig => {
        const { incentiveConfig } = config;

        if (incentiveConfig == undefined) {
          const now = new Date();

          // TODO: ROB 20240102 - UserHistoricalsService has a similar default program config. Align them.
          return {
            endAt: endOfYear(now),
            programId: `${now.getFullYear()}`,
            startAt: startOfYear(now),
          };
        }

        return incentiveConfig;
      }),
    );

    this.incentiveSummaryProgramId$ = this.clientConfig.featuresConfig$.pipe(
      map((config: FeaturesConfig): string | undefined => getProgramId(config.incentiveSummary)),
    );

    this.incentiveProgramProgress$ = combineLatest([
      this.incentiveSummaryProgramId$,
      this.userHistoricalService.currentHistorical$,
      this.userDataService.userData$,
    ]).pipe(
      switchMap((
        [
          programId, historical, userData,
        ]:
        [
          string | undefined, UserHistorical | undefined, UserData | undefined,
        ],
      ): Observable<IncentiveProgramProgress> => {
        // Check if incentiveSummaryProgramId is configured, if so use data for specified programId
        if (programId) {
          return this.getIncentiveProgramProgress(programId, userData);
        }

        // Fallback to current historical data
        return of(this._calculateProgress(historical, userData));
      }),
    );
  }

  public getIncentiveProgramProgress(programId: string, userData?: UserData | undefined): Observable<IncentiveProgramProgress> {
    return this.userHistoricalService.getHistoricalForProgramId(programId).pipe(
      map((historical: UserHistorical | undefined): IncentiveProgramProgress => {
        return this._calculateProgress(historical, userData);
      }),
    );
  }

  private _calculateProgress(historical: UserHistorical | undefined, userData?: UserData | undefined): IncentiveProgramProgress {
    if (historical) {
      const { summary } = historical;
      const {
        'Budget_BudgetProgress_Completed__isbudgetcomplete-true': budgetProgressCompleted = 0,
        'Calculator_DebtPayoff__Visited-complete': calculatorDebtPayoffCompleted = 0,
        Calculator_EmergencySavings__hasAnsweredMinimalFields: calculatorEmergencySavingsCompleted = 0,
        'Calculator_Retirement__Visited-complete': calculatorRetirementCompleted = 0,
        Calculator_SavingsGoal_Completed: calculatorSavingsGoalCompleted = 0,
        Checkup_CheckupResults_Viewed: checkupResults = 0,
        Lessons_LessonProgress_Completed: lessonsCompleted = 0,
      } = summary;

      let budgetCompleted = budgetProgressCompleted > 0;

      if (userData?.isbudgetcomplete) {
        // also award budget completion if user has isbudgetcomplete and income and expenses in historicals
        // This aligns logic with final reporting.
        const budgetFieldsCompleted = Object.keys(summary).some((key: string): boolean =>
          key.startsWith('BudgetField_Expense') && key.startsWith('BudgetField_Income'));

        if (budgetFieldsCompleted) {
          budgetCompleted = true;
        }
      }

      const completedLessonSlugs = Object.keys(summary)
        .filter((key: string): boolean => key.startsWith(`${EVENTS.lessonsLessonProgressCompleted}__`))
        .map((key: string): string => key.split('__')[1] ?? '');

      return {
        completedLessonSlugs,
        hasAnsweredCheckup: hasAnsweredCheckup(summary),
        hasVisitedCheckupResults: Boolean(checkupResults),
        isBudgetComplete: budgetCompleted,
        isDebtPayoffCalculatorComplete: calculatorDebtPayoffCompleted > 0,
        isEmergencySavingsCalculatorComplete: calculatorEmergencySavingsCompleted > 0,
        isRetirementCalculatorComplete: calculatorRetirementCompleted > 0,
        isSavingsGoalCalculatorComplete: calculatorSavingsGoalCompleted > 0,
        lessonsCompleted,
      };
    }

    return {
      completedLessonSlugs: [],
      hasAnsweredCheckup: false,
      hasVisitedCheckupResults: false,
      isBudgetComplete: false,
      isDebtPayoffCalculatorComplete: false,
      isEmergencySavingsCalculatorComplete: false,
      isRetirementCalculatorComplete: false,
      isSavingsGoalCalculatorComplete: false,
      lessonsCompleted: 0,
    };
  }
}

/**
 * Filters the HistoricalSummary for `unanswered` fields
 * which corresponds to the amount of checkup questions answered
 * Less than or equal to 3 means the user has answered the required amount of at least 14 out of 17 questions
 */
const hasAnsweredCheckup = (summary: HistoricalSummary): boolean =>
  Object.keys(summary)
    .filter((key: string): boolean => key.startsWith('Checkup_CheckupResults_Viewed__unanswered-'))
    .map((key: string): number => Number(key.split('__unanswered-')[1]))
    .some((k: number): boolean => k <= 3);
